<template>
  <div class="TryFramework">
    <el-button type="primary">增加试戴</el-button>
    <TryFrameworkContent></TryFrameworkContent>
  </div>
</template>

<script>
export default {
  name: "TryFramework",
  data () {
    return {

    }
  },
  components: {
    TryFrameworkContent: () => import('@/components/specialSurveys/refractive/TryFrameworkContent')
  }
}
</script>

<style scoped lang="scss">
  .TryFramework {
    width: 90%;
    margin: 0 auto;
  }
</style>